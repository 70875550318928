import React from "react";
import { Modal } from "antd";

export default function Instruction({ open, close }) {
  return (
    <Modal
      title="Instrukcja"
      open={open}
      footer={null}
      onCancel={close}
      width={600}
    >
      <>
        <p>1. Wejdź na portal ogłoszeniowy.</p>
        <p>2. Wyszukaj i filtruj ogłoszenia według swoich potrzeb.</p>
        <p>3. Skopiuj link z przeglądarki i wklej go do programu. (Przycisk "Dodaj")</p>
        <p>4. Naciśnij “Start”, aby rozpocząć zbieranie danych.</p>
        <p>
          5. Kliknij “Eksport” → “Pobierz XLSX”, aby zapisać dane w formacie
          Excel.
        </p>
        <p>✅ Gotowe!</p>
      </>
    </Modal>
  );
}
