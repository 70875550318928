import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { useChangePassword } from "../../hooks/user.hook";

export default function Profile({ open, close }) {
  const [form] = Form.useForm();
  const changePassword = useChangePassword();

  const handleFinish = (values) => {
    changePassword.mutate({
      oldPassword: values.currentPassword,
      newPassword: values.newPassword,
    }, {
      onSuccess: () => {
        message.success("Hasło zmienione pomyślnie");
        close();
      },
      onError: (error) => {
        message.error(error.response?.data?.message || error.message);
      }
    });

    form.resetFields();
  };

  return (
    <Modal
      title="Zarządzanie profilem"
      open={open}
      footer={null}
      onCancel={close}
      width={400}
    >
      <h3>Zmień hasło</h3>
      <Form
        title="Zmień hasło"
        form={form}
        layout="vertical"
        size="large"
        onFinish={handleFinish}
      >
        <Form.Item
          label="Aktualne hasło"
          name="currentPassword"
          rules={[{ required: true, message: "Wpisz aktualne hasło" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Nowe hasło"
          name="newPassword"
          rules={[{ required: true, message: "Wpisz nowe haslo" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Potwierdź nowe hasło"
          name="confirmPassword"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Wpisz nowe hasło ponownie" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Hasła nie są takie same"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Zmień hasło
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
