import { useEffect, useState, useCallback, useMemo } from "react";
import {
  Drawer,
  Checkbox,
  Button,
  Space,
  Flex,
  Typography,
  Spin,
  Alert,
  Divider,
  message,
} from "antd";
import { useGetRecords } from "../../hooks/user.hook";
import Table from "../shared/Table";
import { useDownloadRecords, useGetRecordsCount } from "../../hooks/user.hook";

const { Text } = Typography;

export default function ExportComponent({ isOpen, close, scrapingId }) {
  const [hideDuplicatePhones, setHideDuplicatePhones] = useState(false);
  const [isFetching, setIsFetching] = useState(false); // Nowy stan
  const [isDownloading, setIsDownloading] = useState(false); // Stan śledzący pobieranie
  const [fields, setFields] = useState([]);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 100;
  const {
    data: recordsData,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetRecords(scrapingId, page, pageSize);

  // recordsData = { records: [...], totalRecords: 80000 } - example
  useEffect(() => {
    if (recordsData?.records) {
      setRecords(recordsData.records);
    }
    if (recordsData?.totalRecords) {
      setTotalRecords(recordsData.totalRecords);
    }
  }, [recordsData]);

  useEffect(() => {
    if (isOpen) {
      setIsFetching(true); // Zaznaczenie, że zaczynamy pobieranie
      refetch().finally(() => setIsFetching(false)); // Odświeżenie i oznaczenie końca
    }
  }, [isOpen, refetch]);

  // Uruchamianie refetch co 4 sekundy, gdy isOpen jest true i nie ma aktywnego pobierania
  useEffect(() => {
    let interval;

    if (isOpen) {
      interval = setInterval(() => {
        if (!isFetching && !isDownloading) {
          setIsFetching(true); // Oznaczamy, że pobieranie rozpoczęło się
          refetch().finally(() => setIsFetching(false)); // Ustawiamy, że pobieranie zakończyło się
        }
      }, 4000); // Interwał 4 sekundy
    }

    return () => {
      clearInterval(interval); // Czyszczenie interwału przy zamknięciu
    };
  }, [isOpen, refetch, isFetching]); // Zależności

  const [checkedList, setCheckedList] = useState(
    fields.map((item) => item.value)
  );

  const allKeys = useMemo(() => {
    if (!records) return [];
    return Array.from(new Set(records.flatMap(Object.keys)));
  }, [records]);

  useEffect(() => {
    if (isOpen && allKeys.length > 0) {
      setFields(
        allKeys.map((key) => ({
          label: key.replace(key[0], key[0].toUpperCase()),
          value: key,
        }))
      );
      // setCheckedList(allKeys);
    }
  }, [isOpen, allKeys]);

  useEffect(() => {
    if (checkedList.length === 0) {
      setCheckedList(allKeys);
    }
  }, [fields]);

  const { mutate: downloadRecords } = useDownloadRecords();

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleDownload = async (format) => {
    console.log("Rozpoczęcie pobierania...");
    setIsDownloading(true);

    try {
      await new Promise((resolve, reject) => {
        downloadRecords(
          {
            scrapingId,
            fields: checkedList,
            format,
            hideDuplicatePhones,
          },
          {
            onSuccess: resolve,
            onError: reject,
          }
        );
      });
      console.log("Pobieranie zakończone sukcesem");
      message.success("Pobieranie zakończone sukcesem");
    } catch (err) {
      console.error("Błąd podczas pobierania pliku:", err);
      message.error("Błąd podczas pobierania pliku");
    } finally {
      setIsDownloading(false);
      console.log("Stan isDownloading ustawiony na false");
    }
  };

  function countDuplicatePhoneRecords(records) {
    // Tworzenie licznika numerów telefonów
    const phoneCounts = records.reduce((acc, record) => {
      acc[record.phone] = (acc[record.phone] || 0) + 1;
      return acc;
    }, {});

    // Obliczenie liczby rekordów z powtarzającymi się numerami
    const duplicateCount = records.reduce((count, record) => {
      if (phoneCounts[record.phone] > 1) {
        return count + 1;
      }
      return count;
    }, 0);

    return duplicateCount;
  }

  return (
    <div>
      <Drawer
        title="Eksportuj do pliku"
        open={isOpen}
        width="90%"
        onClose={() => {
          close();
        }}
        extra={
          <>
            <Space.Compact size="middle" className="flex items-center">
              <Text type="primary" className="mr-4">
                {totalRecords && `Liczba rekordów: ${totalRecords}`}
              </Text>
              <Button
                loading={isDownloading}
                disabled={isDownloading || fields.length === 0}
                onClick={() => handleDownload("xlsx")}
              >
                Pobierz XLSX
              </Button>
              <Button
                loading={isDownloading}
                disabled={isDownloading || fields.length === 0}
                onClick={() => handleDownload("csv")}
              >
                Pobierz CSV
              </Button>
              <Button
                loading={isDownloading}
                disabled={isDownloading || fields.length === 0}
                onClick={() => handleDownload("json")}
              >
                Pobierz JSON
              </Button>
              {fields.length === 1 && (
                <Button
                  loading={isDownloading}
                  disabled={isDownloading || fields.length === 0}
                  onClick={() => handleDownload("txt")}
                >
                  Pobierz TXT
                </Button>
              )}
            </Space.Compact>
          </>
        }
      >
        <Flex gap={0} vertical>
          <Flex align="center" gap={20} justify="">
            <Checkbox
              checked={hideDuplicatePhones}
              onChange={() => setHideDuplicatePhones(!hideDuplicatePhones)}
            >
              Ukryj wpisy z powtarzającymi się numerami telefonu{" "}
              {/* {records && `(${countDuplicatePhoneRecords(records)})`} */}
            </Checkbox>
          </Flex>
          <Divider />
          <Flex align="center" gap={20} justify="" className="mb-4">
            <Text strong>Pola do eksportu</Text>
            <Space>
              <Button type="link" onClick={() => setCheckedList([])}>
                Odznacz wszystkie
              </Button>
              <Button
                type="link"
                onClick={() =>
                  setCheckedList([...fields.map((item) => item.value)])
                }
              >
                Zaznacz wszystkie
              </Button>
            </Space>
          </Flex>
          <Checkbox.Group
            options={fields}
            value={checkedList}
            onChange={(list) => setCheckedList(list)}
            className="mb-8"
          />
        </Flex>
        {isError && (
          <Alert
            message="Błąd podczas pobierania rekordów"
            description={error.message}
            type="error"
            showIcon
          />
        )}
        <Table
          records={records}
          loading={isLoading}
          activeFields={checkedList}
          hideDuplicatePhones={hideDuplicatePhones}
          onPageChange={handlePageChange}
          totalRecords={totalRecords}
          currentPage={page}
          pageSize={pageSize}
        />
      </Drawer>
    </div>
  );
}
