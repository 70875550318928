import axios from '../middlewares/axios.middleware';
import moment from "moment";
const fileDownload = require("js-file-download");

class UserService {
    static async checkConnection() {
        try {
            const res = await axios.get("/api/ping");
            return res.data;
        } catch (error) {
            console.error("Brak połączenia z serwerem:", error);
            throw error;
        }
    }

    static async getUser() {
        try {
            const res = await axios.get("/api/user");
            return res.data;
        } catch (error) {
            console.error("Błąd podczas pobierania użytkownika:", error);
            throw error;
        }
    }

    static async getCurrentScraping() {
        try {
            const res = await axios.get("/api/getCurrentScraping");
            return res.data;
        } catch (error) {
            console.error("Błąd podczas pobierania obecnego procesu scraping:", error);
            throw error;
        }
    }

    static async getRecords(scrapingId, page, pageSize) {
        try {
            const res = await axios.post("/api/records", { scrapingId, page, pageSize });
            const records = res.data.records.map(record => {
                delete record._id;
                delete record.scrapingId;
                delete record.__v;
                return record;
            });
            const data = {
                records,
                totalRecords: res.data.totalRecords,
                currentPage: res.data.currentPage,
                totalPages: res.data.totalPages,
            };
            return data;
        } catch (error) {
            console.error("Błąd podczas pobierania rekordów:", error);
            throw error;
        }
    }

    static async getRecordsCount(scrapingId) {
        try {
            const res = await axios.post("/api/recordsCount", { scrapingId });
            return res.data;
        } catch (error) {
            console.error("Błąd podczas pobierania rekordów:", error);
            throw error;
        }
    }

    static async createScraping(url, filters) {
        try {
            const res = await axios.post("/api/create", { url, filters });
            return res.data;
        } catch (error) {
            console.error("Błąd podczas tworzenia scrapingu:", error.response?.data || error.message);
            throw error;
        }
    }

    static async startScraping(scrapingId) {
        try {
            const res = await axios.post("/api/start", { scrapingId });
            return res.data;
        } catch (error) {
            console.error("Błąd podczas rozpoczynania scrapingu:", error.response?.data || error.message);
            throw error;
        }
    }

    static async stopScraping(scrapingId) {
        try {
            const res = await axios.post("/api/stop", { scrapingId });
            return res.data;
        } catch (error) {
            console.error("Błąd podczas zatrzymywania scrapingu:", error);
            throw error;
        }
    }

    static async getScrapings() {
        try {
            const res = await axios.get("/api/history");
            return res.data;
        } catch (error) {
            console.error("Błąd podczas pobierania scrapingu:", error);
            throw error;
        }
    }

    // get one scraping 

    static async getScraping(scrapingId) {
        try {
            const res = await axios.post("/api/scraping", { scrapingId });
            return res.data;
        } catch (error) {
            console.error("Błąd podczas pobierania scrapingu:", error);
            throw error;
        }
    }

    static async downloadRecords({ scrapingId, fields, format, hideDuplicatePhones }) {
        try {
            const res = await axios.post("/api/export", { scrapingId, fields, format, hideDuplicatePhones }, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob",
            });
            console.log("Response:", res);

            fileDownload(res.data, `rekordy-${scrapingId}-${moment().format("YYYY-MM-DD")}.${format}`);
        } catch (error) {
            console.error("Błąd podczas pobierania pliku:", error);
            throw error;
        }
    }

    static async deleteScraping(scrapingId) {
        try {
            const res = await axios.post("/api/delete", { scrapingId });
            return res.data;
        } catch (error) {
            console.error("Błąd podczas usuwania scrapingu:", error);
            throw error;
        }
    };


    static async changePassword({ oldPassword, newPassword }) {
        try {
            const res = await axios.post("/api/changePassword", { oldPassword, newPassword });
            return res.data;
        } catch (error) {
            console.error("Błąd podczas zmiany hasła:", error);
            throw error;
        }
    }

    //send feedback

    static async sendFeedback(message) {
        try {
            const res = await axios.post("/api/sendFeedback", { message });
            return res.data;
        } catch (error) {
            console.error("Błąd podczas wysyłania feedbacku:", error);
            throw error;
        }
    }
}

export default UserService;