import { useEffect, useState, useMemo } from "react";
import {
  Button,
  Card,
  Space,
  Progress,
  Typography,
  Spin,
  Flex,
  Tooltip,
  Tag,
} from "antd";
import {
  PlayCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  CalendarOutlined,
  LoadingOutlined,
  StopOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import ExportComponent from "./Export";
import {
  useGetRecordsCount,
  useDeleteScraping,
  useStartScraping,
  useStopScraping,
  useGetScraping,
} from "../../hooks/user.hook";
import formatDate from "../../helpers/formatDate";

const { Text, Link } = Typography;

const decomposeUrl = (url) => {
  if (!url) return [];
  try {
    url = new URL(url);
  } catch (error) {
    console.log("Failed to parse URL:", url);
    console.error(error);
  }
  let path = url.pathname;
  path = decodeURIComponent(path);
  path = path.split("/");
  path = path.map((item) => capitalizeFirstLetter(item));
  if (path[path.length - 1] === "") path.pop();
  return path.slice(1);
};

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export default function ScrapingInstance({ scraping }) {
  const [openExport, setOpenExport] = useState(false);
  const [lastRecordCount, setLastRecordCount] = useState(0);
  const [lastTimestamp, setLastTimestamp] = useState(Date.now());
  const [percentage, setPercentage] = useState(0);
  const [speed, setSpeed] = useState(0); // Prędkość w rekordach na minutę

  const { mutate: deleteScraping, isLoading: isDeleting } = useDeleteScraping();
  const {
    mutate: startScraping,
    isPending: isStarting,
    isSuccess: started,
  } = useStartScraping();
  const {
    mutate: stopScraping,
    isPending: isStoping,
    isSuccess: stopped,
  } = useStopScraping();

  const { data: scrapingData, refetch: refetchScraping } = useGetScraping(
    scraping._id
  );

  const {
    data: recordsCount,
    isLoading,
    isError,
    error,
    refetch: refetchRecordsCount,
  } = useGetRecordsCount(scraping._id);


  // Ustaw interwał do odświeżania danych co 4 sekundy
  useEffect(() => {
    let interval;

    if (scraping.isRunning) {
      interval = setInterval(() => {
        refetchScraping();
        refetchRecordsCount();
      }, 4000); // Co 4 sekundy
    }

    return () => {
      clearInterval(interval);
    };
  }, [scraping.isRunning, refetchScraping, refetchRecordsCount]);

  useEffect(() => {
    if (scrapingData) {
      const calculatedPercentage = calcPercentage(scrapingData);
      setPercentage(calculatedPercentage);
    }
  }, [scrapingData]);

  // Ustaw interwał do obliczania prędkości co 10 sekund
  useEffect(() => {
    let speedInterval;

    if (scraping.isRunning) {
      speedInterval = setInterval(() => {
        const now = Date.now();
        const deltaTime = (now - lastTimestamp) / 1000 / 60; // Czas w minutach
        const deltaRecords = (recordsCount || 0) - lastRecordCount;

        if (deltaTime > 0) {
          const calculatedSpeed = (deltaRecords / deltaTime).toFixed(1); // Oblicz prędkość
          setSpeed(calculatedSpeed);
        }

        setLastRecordCount(recordsCount || 0);
        setLastTimestamp(now);
      }, 10000); // Co 10 sekund
    }

    return () => {
      clearInterval(speedInterval);
    };
  }, [scraping.isRunning, recordsCount, lastRecordCount, lastTimestamp]);

  const closeExportDrawer = () => {
    setOpenExport(false);
  };

  useEffect(() => {
    refetchScraping();
  }, [started, stopped]);

  const calcPercentage = (scraping) => {
    if (scraping.isFinished) {
      return 100;
    }
    if (scraping.lastPageNumber > 0) {
      return Number(
        (scraping.currentPageNumber / scraping.lastPageNumber) * 100 - 1
      ).toFixed(0);
    } else {
      return 0;
    }
  };

  
  const currentScraping = scrapingData || scraping;

  return (
    <div className="w-full">
      <Card
        title={
          <Space>
            <Progress type="dashboard" percent={percentage} size={40} />
            <span className="ml-2">
              {decomposeUrl(currentScraping.initialURL).join(" / ")}
            </span>
          </Space>
        }
        extra={
          <Space>
            {currentScraping.lastPageNumber === 0 ? (
              <Space className="mr-4">
                <Text type="secondary">Nie rozpoczęte</Text>
              </Space>
            ) : (
              <Space className="mr-4">
                <Text type="secondary">Strona:</Text>
                <Text>
                  {currentScraping.currentPageNumber} /{" "}
                  {currentScraping.lastPageNumber}
                </Text>
                <Text type="secondary">Rekordy:</Text>
                {isLoading && (
                  <Spin indicator={<LoadingOutlined spin />} size="small" />
                )}
                {isError && <Text type="danger">Błąd</Text>}
                {!isLoading && !isError && <Text>{recordsCount || 0}</Text>}
              </Space>
            )}
            <Tooltip title="Eksportuj">
              <Button
                icon={<DownloadOutlined />}
                onClick={() => setOpenExport(true)}
              />
            </Tooltip>
            <Tooltip title={currentScraping.isRunning ? "Stop" : "Start"}>
              <Button
                type="primary"
                loading={isStarting || isStoping}
                icon={
                  currentScraping.isRunning ? (
                    <PauseCircleOutlined />
                  ) : (
                    <PlayCircleOutlined />
                  )
                }
                onClick={async () => {
                  if (currentScraping.isRunning) {
                    stopScraping(scraping._id);
                  } else {
                    startScraping(scraping._id);
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Usuń">
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                disabled={currentScraping.isRunning || isDeleting}
                danger
                loading={isDeleting}
                onClick={() => deleteScraping(scraping._id)}
              />
            </Tooltip>
          </Space>
        }
        className="w-[100%]"
      >
        <Flex vertical gap={16}>
          <Text copyable ellipsis code>
            {currentScraping.initialURL}
          </Text>
          {currentScraping.filters.length > 0 && (
            <Flex gap={8}>
              <Text type="secondary">Filtry:</Text>
              {currentScraping.filters.map((filter) => (
                <Tag color="blue" key={filter}>
                  {filter}
                </Tag>
              ))}
            </Flex>
          )}
          <Flex justify="space-between">
            <Text type="secondary">
              <CalendarOutlined className="mr-2" />
              {formatDate(currentScraping.createdAt)}
            </Text>
            {(scraping.isRunning && speed > 0) && (
              <>
                <Text className="flex items-center gap-1.5"  type="secondary">
                  <svg
                  className="mt-0.5"
                    width="20"
                    height="20"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#8C8C8C"
                  >
                    <path d="m14.703125 5.5722656a12 12 0 0 0 -12 12.0000004 12 12 0 0 0 .6894531 3.964843 10.746539 10.746539 0 0 1 -.2949219-2.443359 10.746539 10.746539 0 0 1 10.7460938-10.7460938 10.746539 10.746539 0 0 1 5.982422 1.8261718l2.140625-2.1406249a12 12 0 0 0 -7.263672-2.4609375zm9.148437 3.6035156c-.25173.00423-.507325.1022801-.71875.28125l-9.193359 7.7812498c-.422789.35795-1.416166 1.411952-.002 2.826172 1.414471 1.41445 2.468093.418804 2.826172-.0039l7.783203-9.189453c.358051-.42275.391737-1.0223328 0-1.4140628-.195869-.19587-.443582-.285475-.695313-.28125zm1.84961 3.6074218-2.021484 2.021485a10.746539 10.746539 0 0 1 .90625 4.195312h2.015624a12 12 0 0 0 .101563-1.427734 12 12 0 0 0 -1.001953-4.789063z" />
                  </svg>
                  {speed} rek/min
                </Text>
              </>
            )}
            <Text type="secondary">ID: {currentScraping._id}</Text>
          </Flex>
        </Flex>
      </Card>
      <ExportComponent
        isOpen={openExport}
        close={closeExportDrawer}
        scrapingId={scraping._id}
      />
    </div>
  );
}
