import { Layout, Spin, theme, ConfigProvider, Button } from "antd";
import { useEffect, useState } from "react";
import { useCheckLogin } from "./hooks/auth.hook.js";
import HeaderComponent from "./components/layout/Header.jsx";
import ContentComponent from "./components/layout/Content.jsx";
import FooterComponent from "./components/layout/Footer.jsx";
import LoginComponent from "./components/layout/Login.jsx";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [darkMode, setDarkMode] = useState(localStorage.getItem("theme") === "dark");
  const { data, isLoading, error } = useCheckLogin();

  // Save theme preference in localStorage
  useEffect(() => {
    localStorage.setItem("theme", darkMode ? "dark" : "light");
    document.body.setAttribute("data-theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  // Update login state based on API response
  useEffect(() => {
    if (data) {
      setLoggedIn(data);
    }
  }, [data]);

  const handleLogin = (isLoggedIn) => {
    setLoggedIn(isLoggedIn);
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  // Toggle theme
  const toggleTheme = () => {
    setDarkMode((prev) => !prev);
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }




  return (
    <ConfigProvider
      theme={{
        algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorBgBase: darkMode ? "#16181b" : "#fff",
        }
      }}
    >
      <Layout style={{ background: darkMode ? "#21252B" : "#F4F5F6" }}>
        {loggedIn ? (
          <>
            <HeaderComponent onLogout={handleLogout} darkMode={darkMode} />
            <ContentComponent />
            <FooterComponent darkMode={darkMode} toggleTheme={toggleTheme} />
          </>
        ) : (
          <LoginComponent onLogin={handleLogin} />
        )}
      </Layout>
    </ConfigProvider>
  );
}

export default App;