import { useState } from "react";
import { useLogout } from "../../hooks/auth.hook";
import { Layout, FloatButton } from "antd";
import Feedback from "../modals/Feedback";

import {
  MoonOutlined,
  SunOutlined,
  MailOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const { Footer } = Layout;

export default function FooterComponent({ darkMode, toggleTheme }) {
  const [openFeedback, setOpenFeedback] = useState(false);
  const { mutate: logout } = useLogout();

  return (
    <Footer style={{ textAlign: "center" }}>
      <Feedback open={openFeedback} close={() => setOpenFeedback(false)} />
      <>app.scrape.pl</>
      <FloatButton.Group shape="square" size="large">
        <FloatButton
          tooltip={darkMode ? "Tryb dzienny" : "Tryb nocny"}
          icon={darkMode ? <SunOutlined /> : <MoonOutlined />}
          onClick={toggleTheme}
        />
        <FloatButton
          tooltip="Wyslij zgłoszenie"
          icon={<MailOutlined />}
          onClick={() => setOpenFeedback(true)}
        />
        <FloatButton
          icon={<LogoutOutlined />}
          tooltip="Wyloguj się"
          onClick={() => {
            logout();
            window.location.reload();
          }}
        />
      </FloatButton.Group>
    </Footer>
  );
}
