import { useState } from "react";
import { Modal, Form, Input, Button, Space, message } from "antd";
import { useSendFeedback } from "../../hooks/user.hook";

export default function Feedback({ open, close }) {
  const [feedback, setFeedback] = useState("");

  const { mutate: sendFeedback } = useSendFeedback();

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };
  const handleSubmit = () => {
    sendFeedback(feedback, {
      onSuccess: () => {
        message.success("Zgłoszenie zostało wysłane");
        setFeedback("");
        close();
      },
      onError: () => {
        message.error("Wystąpił błąd podczas wysyłania zgłoszenia.");
      },
    });
  };
  return (
    <Modal title="Zgłoszenie" open={open} footer={null} onCancel={close}>
      <p>
        Jeśli napotkałeś problem lub masz sugestię dotyczącą działania
        aplikacji, skontaktuj się z nami pod adresem
        <a href="mailto:info@scrape.pl"> info@scrape.pl</a>. Lub skorzystaj z
        formularza zgłoszeniowego ponizej.
      </p>

      <Form>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input.TextArea
            placeholder="Opisz czego dotyczy problem"
            size="large"
            value={feedback}
            onChange={handleFeedbackChange}
          />
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            onClick={handleSubmit}
          >
            Wyślij zgłoszenie
          </Button>
        </Space>
      </Form>
    </Modal>
  );
}
