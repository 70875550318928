import { useState } from "react";
import {
  Layout,
  Typography,
  Space,
  Button,
  Badge,
  Progress,
  Flex,  
} from "antd";
import { UserOutlined, CreditCardOutlined } from "@ant-design/icons";
import { useGetUser } from "../../hooks/user.hook";
import Profile from "../modals/Profile";

const { Header } = Layout;
const { Text } = Typography;

export default function HeaderComponent({darkMode}) {
  const { data: user, isLoading, isError, error } = useGetUser(); // Użycie hooka
  const [openProfile, setOpenProfile] = useState(false);

  return (
    <Header
      style={{
        background: darkMode ? "#21252B" : "#fff",
        borderBottom: "1px solid " + (darkMode ? "#30363D" : "#D9D9D9"),
        height: "unset",
      }}
    >
      <div className="w-[925px] m-auto flex justify-between">
        <Flex gap={20} align="center">
          <Flex gap={20} align="center">
            {!isLoading && (
              <Badge
                status={user.active ? "success" : "error"}
                text={user.active ? "Konto aktywne" : "Konto nieaktywne"}
              />
            )}
            {!isLoading && (
              <>
                <Space>
                  <Text type="secondary">Zuycie planu:</Text>
                  <Text>
                    {user.usage} / {user.limit}
                  </Text>
                </Space>
                <Progress
                  className="w-[200px] mr-4"
                  percent={((user.usage / user.limit) * 100).toFixed(1)}
                />
                <Space>
                  <Text type="secondary">Wygasa:</Text>
                  <Text>{user.nextPaymentDate}</Text>
                </Space>
              </>
            )}
          </Flex>
        </Flex>
        <Space>
          <Profile open={openProfile} close={() => setOpenProfile(false)} />
          <Button icon={<UserOutlined />} onClick={() => setOpenProfile(true)}>{user?.username}</Button>
          {/* <Tooltip title="Płatności">
            <Button icon={<CreditCardOutlined />} />
          </Tooltip> */}
        </Space>
      </div>
    </Header>
  );
}
