import { Layout, Button, Flex, Spin, Alert, Space } from "antd";
import { useState } from "react";
import ScrapingInstance from "../scrapings/ScrapingInstance";
import {
  InfoCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Add from "../scrapings/Add";
import { useGetScrapings } from "../../hooks/user.hook";
import Instruction from "../modals/Instruction";
import Feedback from "../modals/Feedback";

const { Content } = Layout;

export default function ContentComponent({ onLogout }) {
  const [openAdd, setOpenAdd] = useState(false);
  const [openInsturction, setOpenInstruction] = useState(false);
  const { data: scrapings, isLoading, isError, error } = useGetScrapings(); // Użycie hooka

  const closeAddModal = () => {
    setOpenAdd(false);
  };
  window.addEventListener("beforeinstallprompt", (e) => {
    console.log("beforeinstallprompt event fired", e);
  });

  return (
    <Content
      className="site-layout"
      style={{ padding: "0 50px", marginTop: 64 }}
    >
      <Space justify="space-between" className="mb-4">
        <Button
          size="large"
          icon={<PlusOutlined />}
          type="primary"
          className="mb-4"
          onClick={() => setOpenAdd(true)}
        >
          Dodaj
        </Button>
        <Button
          icon={<InfoCircleOutlined />}
          type="secondary"
          className="mb-4"
          onClick={() => setOpenInstruction(true)}
        >
          Instrukcja
        </Button>
      </Space>

      <Add isOpen={openAdd} close={closeAddModal} />
      <Instruction open={openInsturction} close={() => setOpenInstruction(false)} />
      {/* <InstallPWAButton /> */}
      {isLoading && (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Spin size="large" />
        </div>
      )}
      {isError && (
        <Alert
          message="Błąd"
          description={error.message}
          type="error"
          showIcon
        />
      )}
      {!isLoading && !isError && (
        <Flex wrap gap={20}>
          {scrapings
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((scraping) => (
              <ScrapingInstance key={scraping._id} scraping={scraping} />
            ))}
        </Flex>
      )}
    </Content>
  );
}
